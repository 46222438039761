<template>
  <div class="group relative h-full f-col overflow-hidden rounded-lg bg-white shadow-xs duration @hover:shadow-md">
    <base-picture
      v-bind="size"
      :src="getResponsiveImageTransformations(image.src, size)"
      :alt="image.alt"
      class="w-full overflow-hidden"
      :props-img="{ class: 'duration-800 group-hover:scale-105' }"
    />
    <div class="px-2 pb-4 pt-3 lg:p-4">
      <base-link :to="url" class="elevate-body-2 line-clamp-4 w-fit">
        {{ title }}
        <span class="absolute-0" />
      </base-link>
      <product-pricing :price :currency class="elevate-body-3" style="margin-top: 0.375rem;" />
    </div>
    <div class="mt-a flex justify-end p-2 lg:p-4">
      <base-button
        :aria-label="$t.add"
        data-test-id="product-card-add"
        @click="ModalOrPanelQuickshop.open({ product, mode: 'modal' })"
      >
        <vf-icon name="quickshop" size="lg" />
      </base-button>
      <modal-or-panel-quickshop :size="$viewport.lg ? 'lg' : undefined" position="bottom" class-content="<lg:pb-0" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ProductVariants } from '#root/api/clients/product/data-contracts'
import type { ImageItem } from '#types/media'
import type { MonetateRating } from '#types/monetate'
import type { PriceFull } from '#types/product'

const { currency, id, image, price, title, url } = defineProps<{
  /**
   * Product name or title
   */
  title: string
  /**
   * Path to product detail page
   */
  url: string
  /**
   * Default product image
   */
  image: ImageItem
  /**
   * Product rating data
   */
  rating?: MonetateRating
  /**
   * Product id
   */
  id: string
  /**
   * Product price currency
   */
  currency: string
  /**
   * Product price data
   */
  price: PriceFull
}>()

const { $viewport } = useNuxtApp()

const product = computed(() => ({
  id,
  name: title,
  url,
  price,
  currency,
  attributes: [],
  variants: {} as ProductVariants
}))

const size = { width: { sm: 1350 }, height: { sm: 1350 } }

const ModalOrPanelQuickshop = createDialog('quickshop', {
  wrapper: () => $viewport.lg ? 'modal' : 'panel',
})
</script>
